import { api } from '@/api'
import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import { useProgrammatic } from '@oruga-ui/oruga-next'

import Home from '../views/Home.vue'
import { Ref, ref } from 'vue'

const { oruga } = useProgrammatic()

export const breadCrumbStack: Ref<Array<string>> = ref([])

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    beforeEnter: () => {
      breadCrumbStack.value = []
    }
  },
  {
    path: '/template/download',
    name: 'Download Template',
    component: () => import('../views/TemplateDownload.vue'),
    beforeEnter: () => {
      breadCrumbStack.value = ['Download Template']
    }
  },
  {
    path: '/template/secure-link',
    name: 'Generate Secure Upload Link',
    component: () => import('../views/SecureLink.vue'),
    beforeEnter: () => {
      breadCrumbStack.value = ['Generate Secure Upload Link']
    }
  },
  {
    path: '/template/secure-link-sent/:email?',
    name: 'Secure Upload Link Generated',
    component: () => import('../views/SecureLinkSent.vue'),
    props: true,
    beforeEnter: (to, from) => {
      // check that redirect is from app
      // avoid fake/scam redirect
      if (from.name === 'Generate Secure Upload Link') {
        breadCrumbStack.value = ['Generate Secure Upload Link', 'Secure Upload Link Generated']

        return true
      } else {
        router.push('/')
      }
    }
  },
  {
    path: '/template/upload/:token?',
    name: 'Upload Template Landing',
    component: () => import('../views/TemplateUploadLanding.vue')
  },
  {
    path: '/template/upload/incidental/:token?',
    name: 'Incidental Occurrences Data Upload',
    component: () => import('../views/IncidentalTemplateUpload.vue'),
    beforeEnter: async (to) => {
      // check for valid jwt auth and redirect accordingly
      const token = to.params.token as string

      try {
        const refresh = await api.auth.refreshToken(token)
        to.params.accessToken = refresh.data.access

        breadCrumbStack.value = ['Incidental Occurrences Data Upload']
        return true
      } catch (e) {
        const errMsg = 'Authentication failed'

        oruga.notification.open({
          component: {
            props: [],
            template: `
            <div class="columns is-mobile">
              <div class="column">
              An error occurred: <b>${errMsg}</b>. Please request another <a href="${router.resolve({
                name: 'Generate Secure Upload Link'
              }).href}">Secure Upload Link</a> or contact support.
              </div>
            </div>
            <div class="columns is-mobile">
              <div class="column has-text-right">
                <o-button type="button" variant="primary" inverted @click="$emit('close')">OK</o-button>
              </div>
            </div>
            `
          },
          variant: 'danger',
          position: 'bottom',
          indefinite: true,
          closable: true
        })

        router.push('/')
      }
      return true
    }
  },
  {
    path: '/template/upload/systematic/:token?',
    name: 'Systematic Survey Data Upload',
    component: () => import('../views/SystematicTemplateUpload.vue'),
    beforeEnter: async (to) => {
      // check for valid jwt auth and redirect accordingly
      const token = to.params.token as string

      try {
        const refresh = await api.auth.refreshToken(token)
        to.params.accessToken = refresh.data.access

        breadCrumbStack.value = ['Systematic Survey Data Upload']
        return true
      } catch (e) {
        const errMsg = 'Authentication failed'

        oruga.notification.open({
          component: {
            props: [],
            template: `
            <div class="columns is-mobile">
              <div class="column">
              An error occurred: <b>${errMsg}</b>. Please request another <a href="${router.resolve({
                name: 'Generate Secure Upload Link'
              }).href}">Secure Upload Link</a> or contact support.
              </div>
            </div>
            <div class="columns is-mobile">
              <div class="column has-text-right">
                <o-button type="button" variant="primary" inverted @click="$emit('close')">OK</o-button>
              </div>
            </div>
            `
          },
          variant: 'danger',
          position: 'bottom',
          indefinite: true,
          closable: true
        })

        router.push('/')
      }
      return true
    }
  },
  {
    path: '/template/upload/systematic-site/:token?',
    name: 'Systematic Survey Site Data Upload',
    component: () => import('../views/SystematicSiteTemplateUpload.vue'),
    beforeEnter: async (to) => {
      // check for valid jwt auth and redirect accordingly
      const token = to.params.token as string

      try {
        const refresh = await api.auth.refreshToken(token)
        to.params.accessToken = refresh.data.access

        breadCrumbStack.value = ['Systematic Survey Site Data Upload']
        return true
      } catch (e) {
        const errMsg = 'Authentication failed'

        oruga.notification.open({
          component: {
            props: [],
            template: `
            <div class="columns is-mobile">
              <div class="column">
              An error occurred: <b>${errMsg}</b>. Please request another <a href="${router.resolve({
                name: 'Generate Secure Upload Link'
              }).href}">Secure Upload Link</a> or contact support.
              </div>
            </div>
            <div class="columns is-mobile">
              <div class="column has-text-right">
                <o-button type="button" variant="primary" inverted @click="$emit('close')">OK</o-button>
              </div>
            </div>
            `
          },
          variant: 'danger',
          position: 'bottom',
          indefinite: true,
          closable: true
        })

        router.push('/')
      }
      return true
    }
  },
  {
    path: '/template/upload/systematic/landing/:token?',
    name: 'Systematic Survey Data Landing',
    beforeEnter: () => {
      breadCrumbStack.value = ['Systematic Survey Data Landing']
    },
    component: () => import('../views/SystematicLanding.vue')
  },
  {
    path: '/template/upload/summary/:submissionID',
    name: 'Submission Status',
    component: () => import('../views/SubmissionSummary.vue')
  },
  {
    path: '/:catchAll(.*)',
    component: () => import('../views/NotFound.vue'),
    beforeEnter: () => {
      breadCrumbStack.value = ['404']
    }
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
